/*
  Project Name : Webmaker
  Author Company : SpecThemes
  Project Date: 5 June, 2018
  Template Developer: vsafaryan50@gmail.com
*/


/*-----------------------------------------
  Slider Container Options
-------------------------------------------*/
.swiper-container {
    width: 100%;
    height: 90%;
  }
  @media screen and (max-width:767px) {
    .swiper-container {
      height: auto;
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    background-position: center;
    background-size: cover;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  
  
  /*-----------------------------------------
    Slider Control Buttons, Paginations
  -------------------------------------------*/
  .swiper-button-next{
    background-repeat: no-repeat;
    background-position: center;
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }
  .swiper-button-prev{
    background-repeat: no-repeat;
    background-position: center;  
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }
  .swiper-pagination-bullet-active{
    background: #fff;
  }
  
  
  /*-----------------------------------------
    Overlays For Slides
  -------------------------------------------*/
  .light-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5; 
  }
  
  .medium-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6; 
  }
  
  .dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
  }
  
  
  
  /*-----------------------------------------
    Slider Content Captions
  -------------------------------------------*/
  .slider-content{
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  @media screen and (max-width:767px) {
    .slider-content{
      padding: 70px 30px;
    }
  }
  .slider-content h2{
    font-size: 65px;
    color: #fff;
    line-height: 1.4;
    font-weight: 600;
  }
  
  @media screen and (max-width:767px) {
    .slider-content h2{
      font-size: 30px;
      color: #fff;
      line-height: 1.4;
      font-weight: 600;
    }
  }
  
  .slider-content h3{
    font-size: 50px;
    color: #fff;
    line-height: 1.4;
    font-weight: 600;
  }
  @media screen and (max-width:767px) {
    .slider-content h3{
      font-size: 25px;
      color: #fff;
      line-height: 1.4;
      font-weight: 600;
    }
  }
  
  
  .slider-content h4{
    font-size: 22px;
    color: #fff;
    line-height: 1.4;
    font-weight: 600;
    margin: 5px 0px;
  }
  @media screen and (max-width:767px) {
    .slider-content h4{
      font-size: 20px;
      color: #fff;
      line-height: 1.4;
      font-weight: 600;
    }
  }
  
  .slider-content h5{
    font-size: 20px;
    color: #fff;
    line-height: 1.4;
    font-weight: 600;
    margin: 15px 0px;
  }
  
  
  .slider-content p{
    font-size: 15px;
    color: rgba(255, 255, 255, .7);
    line-height: 1.9;
    font-weight: 400;
    max-width: 100%;
    margin-top: 10px;
    word-wrap: break-word;
  }
  @media screen and (max-width:767px) {
    .slider-content p{ 
      width: auto;
    }
  }
  
  
  
  /*-----------------------------------------
    Slider Parallax
  -------------------------------------------*/
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  
  
  
  
  /*-----------------------------------------
  Revolution Slider Styles
  -------------------------------------------*/
  .rev-slider-content{
    z-index: 19 !important;
    white-space: nowrap; 
    font-size: 90px; 
    line-height: 90px; 
    font-weight: 500; 
    color: #ffffff !important; 
  }
  
  .revolution-btn{
    z-index: 21 !important; 
    white-space: nowrap; 
    font-size: 18px; 
    line-height: 60px; 
    font-weight: 500; 
    color: rgba(255,255,255,1); 
    letter-spacing: 1px;
    border-color:rgba(0,0,0,1);
    border-radius:30px 30px 30px 30px;
    outline:none;
    box-shadow:none;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    cursor:pointer;
    text-decoration: none;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
  }
  
  .tp-bannertimer{
    height: 5px; 
    background: #fff;
    opacity: .5;
  }
  
  .revolution-btn-2{
    z-index: 20 !important; 
    min-width: 480px; 
    max-width: 480px; 
    white-space: normal; 
    font-size: 25px; 
    line-height: 35px; 
    font-weight: 400; 
    color: #ffffff; 
    letter-spacing: 0px;
  }
  
  .tp-bullet {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      background: rgba(255,255,255,.3);
      cursor: pointer;
  }
  
  
  
  
  /*-----------------------------------------
  Main Image Section Styles
  -------------------------------------------*/
  .main-image-section{
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: 90px 0px 90px 0px;   
    min-height: 85%;  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;  
  }
  
  .main-image-content{
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1; 
  }
  
  
  .main-image-content h2{
    font-size: 50px;
    color: #fff;
    line-height: 1.4;
    font-weight: 600;
  }
  
  @media screen and (max-width:767px) {
    .main-image-content h2{
      font-size: 25px;
      color: #fff;
      line-height: 1.4;
      font-weight: 600;
    }
  }
  
  .main-image-content h3{
    font-size: 45px;
    color: #fff;
    line-height: 1.4;
    font-weight: 600;
  }
  
  .main-image-content p{
    font-size: 15px;
    color: rgba(255, 255, 255, .7);
    line-height: 1.9;
    font-weight: 400;
    max-width: 100%;
    margin-top: 10px;
    word-wrap: break-word;
  }
  @media screen and (max-width:767px) {
    .main-image-content p{ 
      width: auto;
    }
  }
  
  
  
  /*-----------------------------------------
  Main Video Section Section Styles
  -------------------------------------------*/
  .main-video-content {
      position: relative;
      text-align: center;
      height: 550px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
  }
  
  @media (max-width: 767px) {
    .main-video-section{
      height: auto;
    }
    .main-video-content{
      height: auto;
      padding: 50px 0px;
    } 
  }
  
  .main-video-content h2{
    position: relative;
    font-size: 60px;
    font-weight: 400;
    color: #111;
    margin: 5px 0px;  
  }
  @media (max-width: 767px) {
    .main-video-content h2{
      font-size: 30px;
      font-weight: 400;
    } 
  }
  .main-video-content h3{
    position: relative;
    font-size: 50px;
    font-weight: 400;
    color: #111;
    margin: 5px 0px;  
  }
  @media (max-width: 767px) {
    .main-video-content h3{
      font-size: 30px;
      font-weight: 400;
    } 
  }
  .main-video-content h4{
    position: relative;
    font-size: 40px;
    font-weight: 400;
    color: #111;
    margin: 5px 0px;  
  }
  .main-video-content h5{
    position: relative;
    font-size: 30px;
    font-weight: 400;
    color: #111;
    margin: 5px 0px;  
  }
  .main-video-content h6{
    position: relative;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
    color: #111;
    margin: 5px 0px;  
  }
  @media (max-width: 767px) {
    .main-video-content h6{
      font-size: 15px;
      font-weight: 400;
    } 
  }
  
  
  
  
  /*-----------------------------------------
  Main Image Section Section Styles
  -------------------------------------------*/
  .main-image-section{
      position: relative;
      display: table;
      height: calc(100% - 150px);
      width: 100%;
      padding: 0px;
  }
  .main-image-content {
      position: relative;
      text-align: center;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
  }
  
  @media (max-width: 767px) {
    .main-image-section{
      height: auto;
    }
    .main-image-content{
      height: auto;
      padding: 50px 0px;
    } 
  }
  
  .main-image-content h2{
    position: relative;
    font-size: 70px;
    font-weight: 400;
    display: block;
    color: #111;
    margin: 5px 0px;  
  }
  @media (max-width: 767px) {
    .main-image-content h2{
      font-size: 30px;
      font-weight: 400;
    } 
  }
  .main-image-content h3{
    position: relative;
    font-size: 50px;
    font-weight: 400;
    color: #111;
    display: block;
    margin: 5px 0px;  
  }
  @media (max-width: 767px) {
    .main-image-content h3{
      font-size: 30px;
      font-weight: 400;
    } 
  }
  .main-image-content h4{
    position: relative;
    font-size: 40px;
    font-weight: 400;
    color: #111;
    margin: 5px 0px;  
  }
  .main-image-content h5{
    position: relative;
    font-size: 30px;
    font-weight: 400;
    color: #111;
    margin: 5px 0px;  
  }
  .main-image-content h6{
    position: relative;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.6;
    color: #111;
    margin: 5px 0px;  
  }
  
  